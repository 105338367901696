
@tailwind base;
@tailwind components;
@tailwind utilities;


.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.about{
    height: 47vh;
    padding-top: 10px;
}

.active{
  @apply md:text-yellow-600
  
}

/* mobile  */
@media screen and (max-width: 761px){
  .active{
    color: white;
    background-color: #ec4899;
  }
  .productDetail{
    display: block;
  }
}